<template>
  <div class="" style="background-color: #f3f4f8; min-height: 100vh">
    <!-- <Card
      v-if="Show1"
      :cardList="cardList"
      :InitFlowNodeList="InitFlowNodeList"
      :AltUserList="AltUserList"
    /> -->

    <div class="information" style="">
      <!-- <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了~"
        offset="30"
        :immediate-check="false"
        @load="onLoad"
      > -->
      <div class="center" v-for="item in commNoticeList" :key="item.index">
        <div class="ul">
          <div class="Title">{{ item.CardTypeDesc }}</div>
          <div class="label"><span>申请人：</span>{{ item.Name }}</div>
          <div class="label"><span>申请时间：</span>{{ item.AppTime }}</div>
          <div class="label" v-if="item.Code"><span>队员编号：</span>{{ item.Code }}</div>
          <div class="label" v-if="item.Passport"><span>护照号：</span>{{ item.Passport }}</div>
          <div class="label" v-if="item.Allergy"><span>药物过敏史：</span>{{ item.Allergy }}</div>
          <div class="label" v-if="item.BloodDesc"><span>血型：</span>{{ item.BloodDesc }}</div>
          <div class="label"><span>紧急联系人：</span>{{ item.TouchMan }}</div>
          <div class="label"><span>紧急联系电话：</span>{{ item.TouchTel }}</div>
          <div class="label"><span>收件人：</span>{{ item.Recipient }}</div>
          <div class="label"><span>收件人电话：</span>{{ item.RecMobile }}</div>
          <div class="label" v-if="item.RecAddr"><span>收件地址：</span>{{ item.RecAddr }}</div>
          <div class="label"><span>状态：</span>{{ item.StatusDesc }}</div>
          <div class="label" v-if="item.Remark != null && item.Remark != ''"><span>备注：</span>{{ item.Remark }}</div>
          <div class="label" v-if="item.DeliveryCode"><span>单号：</span>{{ item.DeliveryCode }}</div>
          <div style="height: 26px" v-if="item.Status == 1 || item.Status == 4 || item.Status == 2">
            <van-button
              v-if="item.Status == 2 || item.Status == 4"
              style="float: right; border-radius: 8px"
              @click="rePay(item)"
              type="info"
              size="small"
              >重新支付</van-button
            >

            <van-button
              v-if="item.Status == 1 || item.Status == 2 || item.Status == 4"
              style="float: right; border-radius: 8px; margin-right: 10px"
              @click="handelTermination(item)"
              type="danger"
              size="small"
              >终止申请</van-button
            >
            <van-button
              v-if="item.Status == 7"
              style="float: right; border-radius: 8px; margin-right: 10px"
              @click="handelRefund(item)"
              type="warning"
              size="small"
              >退款申请</van-button
            >
            <van-button
              style="float: right; border-radius: 8px;margin-right: 10px;"
              @click="preview(item)"
              type="primary"
              size="small"
              >预览</van-button
            >
          </div>
        </div>
      </div>
      <van-empty v-if="commNoticeList.length == 0" description="暂无数据" />

      <!-- <van-button v-if="showBtn" @click="backTop" class="backTop"
          ><img
            src="https://dximg.dexian.ren/lczhsq/top.png"
            style="width: 13px; vertical-align: middle"
            alt=""
        /></van-button> -->
      <!-- </van-list> -->
    </div>

    <div class="bottom" v-if="ShowBtn">
      <van-button @click="applyBtn">发起申请</van-button>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { getToken, setToken } from '@/utils/auth'

import {
  StopMakeCard,
  Refund,
  GenerateMakeCard,
  GetDraftMakeCard,
  ExistMakingApply,
  RePaymentByWechat,
  InitFlowNode,
  GetMyMakeCard,
  GetWxGetMemberInfo,
} from '@/api/user'
export default {
  // 组件名称
  name: '',
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  // 组件状态值
  data() {
    return {
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      cardList: {},
      InitFlowNodeList: [],
      AltUserList: [],
      ShowBtn: false,
      commNoticeList: [],
      listfrom: {
        page: 1,
        limit: 99,
        cardType: 1,
      },
      openId: '',
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created() {},
  mounted() {
    console.log(this.$route.query)
    this.openId = this.$route.query.openid
    setToken(this.$route.query.token)
    this.existMakingApply()
    this.getList()
  },
  // 组件方法
  methods: {
    // 预览
    preview(val) {
      // GetWxGetMemberInfo({ idCard: val.idCard }).then((res) => {
      //   if (res.data.code == 0) {
      //     console.log(res.data.data)
      //   }
      // })
      if (val.HeadImg) {
        val.HeadImg = val.HeadImg.replace(/\//g, '*')
      }
      if (val.QRCode) {
        val.QRCode = val.QRCode.replace(/\//g, '*')
      }
      val.RecipientPy = val.Pinyin
      var path = '/credential/' + JSON.stringify(val)
      this.$router.push(path)
      // if (window.__wxjs_environment === 'miniprogram') {
      //   window.wx.miniProgram.navigateTo({
      //     url: '../../../public/goto?path=' + path,
      //   })
      // } else {
      //   this.$router.push(path)
      // }
    },
    // 发起申请
    applyBtn() {
      this.$router.push({
        path: '/shop/makeCard1',
      })
    },
    onLoad() {
      this.listfrom.page = this.listfrom.page + 1
      this.loading = true
      this.getList()
    },
    // 获取我的制证列表申请
    getList() {
      this.listfrom.accToken = getToken()
      GetMyMakeCard(this.listfrom).then((res) => {
        this.loading = false
        if (res.data.code == 0) {
          let rows = res.data.data //请求返回当页的列表
          // 加载状态结束
          if (rows == null || rows.length === 0) {
            // 加载结束
            console.log('结束')
            this.finished = true
            return
          }
          // 将新数据与老数据进行合并
          this.commNoticeList = this.commNoticeList.concat(rows)
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.commNoticeList.length >= res.data.count) {
            this.finished = true
          }
          this.commNoticeList.forEach((i) => {
            i.AppTime = i.AppTime.replace('T', ' ')
          })
        } else {
          this.finished = true
        }
      })
    },
    existMakingApply() {
      ExistMakingApply({
        accToken: getToken(),
        cardType: 1,
      }).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data)
          if (res.data.data == 1) {
            // 存在
            this.ShowBtn = false
            // this.getDraftMakeCard();
          } else {
            // 不存在
            this.ShowBtn = true
          }
        }
      })
    },
    // 制证申请
    makeCardId() {
      // 1. 生成制证id
      GenerateMakeCard({
        accToken: getToken(),
        CardType: 1,
      }).then((res) => {
        if (res.code == 0) {
          // this.Show = true;
          console.log(res.data, 'cardId')
          GetDraftMakeCard({ accToken: getToken(), cardType: 1 }).then((res) => {
            if (res.data.code == 0) {
              this.cardList = res.data.data
              InitFlowNode({ instanceId: res.data.data.InstanceId }).then((res) => {
                if (res.code == 0) {
                  console.log(res.data.data, '进度流程')
                  this.Show = true
                  this.activeTab = 'makeCard'
                  this.InitFlowNodeList = res.data.data
                  this.InitFlowNodeList.forEach((i) => {
                    if (i.SelfSelect == 1) {
                      // 自选 =>设置自选审批人
                      this.AltUserList = i.AltUserList
                    }
                  })
                }
              })
            }
          })
        } else {
          Toast(res.data.msg)
        }
      })
    },
    // 2.获取正在起草的制证申请
    getDraftMakeCard() {
      GetDraftMakeCard({ accToken: getToken(), cardType: 1 }).then((res) => {
        console.log(res, '正在起草的制证申请')
        if (res.data.code == 0) {
          // if (res.data) {
          //   this.Show = true;
          // } else {
          //   this.Show = false;
          // }
          this.cardList = res.data.data
          InitFlowNode({
            accToken: getToken(),
            instanceId: res.data.data.InstanceId,
          }).then((res) => {
            if (res.data.code == 0) {
              console.log(res.data.data, '进度流程')
              this.InitFlowNodeList = res.data.data
              this.InitFlowNodeList.forEach((i) => {
                if (i.SelfSelect == 1) {
                  // 自选 =>设置自选审批人
                  this.AltUserList = i.AltUserList
                }
              })
            }
          })
        } else {
          Toast(res.data.msg)
        }
      })
    },

    // 终止 申请流程
    handelTermination(row) {
      Dialog.confirm({
        title: '温馨提示',
        message: '确定要终止该申请吗?',
      }).then(() => {
        // on confirm
        StopMakeCard({
          MakeId: row.MakeId,
          accToken: getToken(),
        })
          .then((res) => {
            if (res.data.code === 0) {
              Toast.success('操作成功')
              setTimeout(() => {
                if (window.__wxjs_environment === 'miniprogram') {
                  window.wx.miniProgram.navigateBack({
                    delta: 1,
                  })
                } else {
                  this.$router.go(-1)
                }
              }, 1000)
            } else {
              Toast.fail('操作失败,' + res.data.msg)
            }
          })
          .catch(() => {
            // on cancel
          })
      })
    },

    // 退款 申请
    handelRefund(row) {
      Dialog.confirm({
        title: '温馨提示',
        message: '是否确认申请退款?',
      }).then(() => {
        // on confirm
        Refund({
          MakeId: row.MakeId,
          accToken: getToken(),
        })
          .then((res) => {
            if (res.data.code === 0) {
              Toast.success('操作成功')
              setTimeout(() => {
                if (window.__wxjs_environment === 'miniprogram') {
                  window.wx.miniProgram.navigateBack({
                    delta: 1,
                  })
                } else {
                  this.$router.go(-1)
                }
              }, 1000)
            } else {
              Toast.fail('操作失败,' + res.data.msg)
            }
          })
          .catch(() => {
            // on cancel
          })
      })
    },
    // 重新支付
    rePay(row) {
      RePaymentByWechat({
        accToken: getToken(),
        OpenID: this.openId,
        MakeId: row.MakeId,
      }).then((res) => {
        var Data = JSON.stringify(res.data.data)
        Data = Data.replace(/\=/g, '*')
        if (window.__wxjs_environment === 'miniprogram') {
          window.wx.miniProgram.navigateTo({
            url: '../../../public/payCard?user=0' + '&Data=' + Data,
          })
        } else {
          alert('请在小程序中打开')
        }
      })
    },
  },
}
</script>

<style scoped>
.bottom {
  width: 100%;
  height: 60px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  text-align: center;

  box-sizing: border-box;
  padding: 6px 0 0;
}

.bottom button {
  background: linear-gradient(to right, #56adda, #187fc8);
  color: #fff;
  width: 90%;
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
}

.ul {
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 17px 12px;
}

.Title {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 550;
  color: #333333;
  padding-bottom: 7px;
}

.label {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 27px;
}

.label span {
  color: #999999;
}
.information {
  padding-bottom: 100px;
}
</style>
